//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PlanModel } from '@/modules/plan/plan-model';
import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

const { fields } = PlanModel;
// function i18n(key, args) {
//       // return i18n(code);
//       return this.$t(key, args);
//     }
export default {
  name: 'app-branches-list-table',

  async mounted() {
    // this.doMountTable(this.$refs.table);
    await this.doFetchBranches(this.currentUser.companyId)
  },

  data() {
    return {
      language: getLanguageCode(),
      selected_ID: '',
      Pagination: {
        rowsPerPage: 20
      },
      columns: [
        {
          name: 'name',
          field: 'name',
          label: i18n('home.fields.branchName'),
          format: val => `${val}`,
          align: 'left',
          // sortable: true,  
          // required: true,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'branch/list/rows',   
      loading: 'plan/list/loading',
      pagination: 'plan/list/pagination',
      currentUser: 'auth/currentUser',
      destroyLoading: 'plan/destroy/loading',
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doMountTable: 'plan/list/doMountTable',
      doDestroy: 'plan/destroy/doDestroy',
      doFetchBranches: 'branch/list/doFetch'
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return PlanModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = PlanModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
  },
};
