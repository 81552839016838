//
//
//
//
//
//
//

import uuid from 'uuid/v4';
import Chart from 'chart.js';
// import cash from "cash-dom";

export default {
  name: 'app-home-chart',

  // props: ['config'],
  props: {

    config: {
      type: Object,
      // default: {},
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
    // refKey: {
    //   type: String,
    //   default: null
    // }
  },

  data() {
    return {
      id: uuid(),
    };
  },
  computed: {
    chartHeight() {
      return this.height > 0 ? this.height : null
    },
    chartWidth() {
      return this.width > 0 ? this.width : null
    }
  },
  mounted() {
    const ctx = document.getElementById(this.id);
    new Chart(ctx, this.config);
  },
};
