//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PlanModel } from '@/modules/plan/plan-model';
import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

const { fields } = PlanModel;

export default {
  name: 'app-active-trip-list-table',

  async mounted() {
    await this.doFetch({
          ownerId: this.currentUser.companyId,
          filter:{
            status: 'started'
          },
          orderBy: "createdAt",
          pagination: {
            sortBy: "desc",
            limit: this.getItemsPerPage
          }
        })
    // this.doMountTable(this.$refs.table);
  },

  data() {
    return {
      language: getLanguageCode(),
      selected_ID: '',
      Pagination: {
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'trip',
          field: 'trip',
          label: i18n('home.fields.trip'),
          format: val => `${val}`,
          align: 'left',
          // sortable: true,  
          // required: true,
        },
        {
          name: 'startCity',
          field: 'city',
          label: i18n('home.fields.startCity'),
          format: val => `${val}`,
          align: 'startCity',
          // sortable: true,  
          // required: true,
        },
        {
          name: 'tripCaptain',
          field: 'tripCaptain',
          label: i18n('home.fields.captain'),
          align: 'center',
          // sortable: true,  
          // required: true,
        },
        {
          name: 'paymentMethod',
          field: 'paymentMethod',
          label: i18n('home.fields.paymentMethod'),
          align: 'center',
          // sortable: true,  
          // required: true,
        },
      ],
      // rows: [
      //   {
      //     trip: 'Plan A',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '2 days',
      //     paymentMethod: 'Cash',
      //   },
      //   {
      //     trip: 'Plan A',
      //     city: 'Makkah',
      //     guide: 'Nader Ali',
      //     duration: '1 days',
      //     paymentMethod: 'Apple Pay',
      //   },
      //   {
      //     trip: 'Plan B',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '3 days',
      //     paymentMethod: 'Cash',
      //   },
      //   {
      //     trip: 'Plan A',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '2 days',
      //     paymentMethod: 'Cash',
      //   },
      //   {
      //     trip: 'Plan A',
      //     city: 'Makkah',
      //     guide: 'Nader Ali',
      //     duration: '1 days',
      //     paymentMethod: 'Apple Pay',
      //   },
      //   {
      //     trip: 'Plan B',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '3 days',
      //     paymentMethod: 'Cash',
      //   },
      //   {
      //     trip: 'Plan A',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '2 days',
      //     paymentMethod: 'Cash',
      //   },
      //   {
      //     trip: 'Plan A',
      //     city: 'Makkah',
      //     guide: 'Nader Ali',
      //     duration: '1 days',
      //     paymentMethod: 'Apple Pay',
      //   },
      //   {
      //     trip: 'Plan B',
      //     city: 'Jeddah',
      //     guide: 'Ahmed ali',
      //     duration: '3 days',
      //     paymentMethod: 'Cash',
      //   },
      // ],
    }
  },
  computed: {
    ...mapGetters({
      rows: 'trip/list/rows',   
      loading: 'plan/list/loading',
      pagination: 'plan/list/pagination',
      currentUser: 'auth/currentUser',
      destroyLoading: 'plan/destroy/loading',

      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
    // tripCaptain(){
    //   return this.rows.forEach(row => {
    //     row.guides.filter(el => {
    //      return el.id == row.captainId
    //     })
    //   });
    // }
  },

  methods: {
    getCaptain(row){
      const tripCaptain = row.guides.filter(el => {
        return el.id == row.captainId
      })[0].fullName
      console.log(tripCaptain);
      debugger
      return tripCaptain
    },
    ...mapActions({
      doMountTable: 'plan/list/doMountTable',
      doDestroy: 'plan/destroy/doDestroy',
      doFetch: 'trip/list/doFetch',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return PlanModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = PlanModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
  },
};
