import { render, staticRenderFns } from "./active-trip-list-table.vue?vue&type=template&id=56520e09&"
import script from "./active-trip-list-table.vue?vue&type=script&lang=js&"
export * from "./active-trip-list-table.vue?vue&type=script&lang=js&"
import style0 from "./active-trip-list-table.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QTr,QTd,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTr,QTd,QImg})
