import { render, staticRenderFns } from "./branches-list-table.vue?vue&type=template&id=5416e2bd&"
import script from "./branches-list-table.vue?vue&type=script&lang=js&"
export * from "./branches-list-table.vue?vue&type=script&lang=js&"
import style0 from "./branches-list-table.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QTable,QTd,QIcon,QImg} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QTd,QIcon,QImg})
