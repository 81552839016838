//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
// import { i18n } from '@/vueI18n';
// import i18n from '@/vueI18n';
// import moment from 'moment';

import TransactionCard from '@/modules/home/components/transaction-card.vue'

// import firebase from 'firebase/app';
import 'firebase/firestore'

// import { TransactionPermissions } from '@/modules/transaction/transaction-permissions';
// import { TransactionModel } from '@/modules/transaction/transaction-model';
// const { fields } = TransactionModel;

export default {
  name: 'app-transaction-list',

  components: {
    [TransactionCard.name]: TransactionCard,
  },

  async mounted() {
      await this.doFetchRecentTransactions()
  },
  // firestore() {
  //   return {
  //     transactions: firebase.firestore().collection('transaction').orderBy('createdAt', 'desc').limit(5), 
  //   }
  // },

  data() {
    return {
      selected_ID: '',
      // transactions: [],
    }
  },
  computed: {
    ...mapGetters({
      transactions: 'payment/list/recentTransactions',   
      // loading: 'transaction/list/loading',
      // pagination: 'transaction/list/pagination',
      // destroyLoading: 'transaction/destroy/loading',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // fields() {
    //   return fields;
    // },
  },

  methods: {
    ...mapActions({
      doMountTable: 'transaction/list/doMountTable',
      doDestroy: 'transaction/destroy/doDestroy',
      doFetchRecentTransactions: 'payment/list/doFetchRecentTransactions',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
  },
};
