//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { i18n } from '@/vueI18n';
// import HomeChart from '@/modules/home/components/home-chart.vue';
import ActiveTripListTable from '@/modules/home/components/active-trip-list-table.vue';
import BranchesListTable from '@/modules/home/components/branches-list-table.vue';
import TransactionList from '@/modules/home/components/transaction-list.vue';
import ActiveTourGuidesListTable from '@/modules/home/components/active-tour-guides-list-table.vue';
import { mapActions, mapGetters } from 'vuex';
import ReportBox from '@/modules/home/components/report-box.vue';

import HomeChart from '@/modules/home/components/home-chart.vue';

export default {
  name: 'app-home-page',

  components: {
    [HomeChart.name]: HomeChart,
    [ActiveTripListTable.name]: ActiveTripListTable,
    [BranchesListTable.name]: BranchesListTable,
    [TransactionList.name]: TransactionList,
    [ActiveTourGuidesListTable.name]: ActiveTourGuidesListTable,
    [ReportBox.name]: ReportBox,
  },

  async created() {
    await this.doFetchDashboard();
    if (this.isMobile) {
      this.collapseMenu();
    }
  },

  data() {
    return {
      // line: {
      //   type: "line",
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     scales: {
      //       xAxes: [
      //         {
      //           ticks: {
      //             fontSize: '12',
      //             fontColor: '#777777'
      //           },
      //           gridLines: {
      //             display: false
      //           }
      //         }
      //       ],
      //       yAxes: [
      //         {
      //           ticks: {
      //             fontSize: '12',
      //             fontColor: '#777777',
      //             callback: function(value) {
      //               return '$' + value
      //             }
      //           },
      //           gridLines: {
      //             color: '#D8D8D8',
      //             zeroLineColor: '#D8D8D8',
      //             borderDash: [2, 2],
      //             zeroLineBorderDash: [2, 2],
      //             drawBorder: false
      //           }
      //         }
      //       ]
      //     }
      //   },
      //   data: {
      //     labels: [
      //       // i18n("home.charts.months.1"),
      //       // i18n("home.charts.months.2"),
      //       // i18n("home.charts.months.3"),
      //       // i18n("home.charts.months.4"),
      //       // i18n("home.charts.months.5"),
      //       // i18n("home.charts.months.6"),
      //       // i18n("home.charts.months.7")
      //       'Jan',
      //       'Feb',
      //       'Mar',
      //       'Apr',
      //       'May',
      //       'Jun',
      //       'Jul',
      //       'Aug',
      //       'Sep',
      //       'Oct',
      //       'Nov',
      //       'Dec'
      //     ],
      //     datasets: [
      //       {
      //         label: '# of Votes',
      //         data: [0, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200],
      //         borderWidth: 2,
      //         borderColor: '#B2DF8A',
      //         backgroundColor: 'transparent',
      //         pointBorderColor: '#B2DF8A',
      //         pointBorderWidth: 1,
      //         pointHoverRadius: 5,
      //       },
      //       {
      //         label: '# of Votes',
      //         data: [0, 300, 400, 560, 320, 600, 720, 850, 690, 805, 1200, 1010],
      //         borderWidth: 2,
      //         borderDash: [2, 2],
      //         borderColor: '#A6CEE3',
      //         backgroundColor: 'transparent',
      //         pointBorderColor: '#A6CEE3'
      //       },
      //       {
      //         label: '# of Votes',
      //         data: [0, 250, 150, 250, 400, 200, 550, 400, 950, 900, 800, 1100, 800, 750, 1100],
      //         borderWidth: 2,
      //         borderColor: '#FFB900',
      //         backgroundColor: 'transparent',
      //         pointBorderColor: '#FFB900',
      //         pointBorderWidth: 1,
      //         pointHoverRadius: 5,
      //       },
      //     ]
      //   }
      // },
      // completedTrips: {
      //   type: "doughnut",
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     cutoutPercentage: 80
      //   },
      //   percentage: 85,
      //   data: {
      //     labels: [ 'Dark','Yellow',],
      //     datasets: [
      //       {
      //         data: [85, 15],
      //         backgroundColor: [
      //           'rgb(244,184,5)',
      //           'rgb(204,204,204)'
      //             // 'rgba(255, 99, 132, 0.2)',
      //           // 'rgba(54, 162, 235, 0.2)',
      //           // 'rgba(255, 206, 86, 0.2)',
      //           // 'rgba(75, 192, 192, 0.2)',
      //           // 'rgba(153, 102, 255, 0.2)',
      //           // 'rgba(255, 159, 64, 0.2)'
      //         ],
      //         // hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
      //         borderWidth: 0,
      //         borderColor: '#fff'
      //       }
      //     ]
      //   }
      // },
      // companiesProfit:{
      //   type: "doughnut",
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     cutoutPercentage: 80
      //   },
      //   percentage: 12.5,
      //   data: {
      //     labels: [ 'Dark','Yellow',],
      //     datasets: [
      //       {
      //         data: [12.5, 87.5],
      //         backgroundColor: [
      //           'rgb(244,184,5)',
      //           'rgb(204,204,204)'
      //             // 'rgba(255, 99, 132, 0.2)',
      //           // 'rgba(54, 162, 235, 0.2)',
      //           // 'rgba(255, 206, 86, 0.2)',
      //           // 'rgba(75, 192, 192, 0.2)',
      //           // 'rgba(153, 102, 255, 0.2)',
      //           // 'rgba(255, 159, 64, 0.2)'
      //         ],
      //         // hoverBackgroundColor: ['#FF8B26', '#FFC533', '#285FD3'],
      //         borderWidth: 0,
      //         borderColor: '#fff'
      //       }
      //     ]
      //   }
      // },
      // totalOffers: {
      //   type: "doughnut",
      //   options: {
      //     legend: {
      //       display: false
      //     },
      //     cutoutPercentage: 80
      //   },
      //   percentage: 85,
      //   data: {
      //     labels: [ 'Dark','Yellow',],
      //     datasets: [
      //       {
      //         data: [85, 15],
      //         backgroundColor: [
      //           'rgb(244,184,5)',
      //           'rgb(204,204,204)'
      //         ],
      //         borderWidth: 0,
      //         borderColor: '#fff'
      //       }
      //     ]
      //   }
      // },
      // companies: {
      //         type: "line",
      //         options: {
      //           legend: {
      //             display: false
      //           },
      //           scales: {
      //             xAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ],
      //             yAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ]
      //           },
      //           // scales: {
      //           //   xAxes: [
      //           //     {
      //           //       display: false
      //           //     }
      //           //   ],
      //           //   yAxes: [
      //           //     {
      //           //       display: true
      //           //     }
      //           //   ]
      //           // }
      //         },
      //         data: {
      //           labels: [
      //             // i18n("home.charts.months.1"),
      //             // i18n("home.charts.months.2"),
      //             // i18n("home.charts.months.3"),
      //             // i18n("home.charts.months.4"),
      //             // i18n("home.charts.months.5"),
      //             // i18n("home.charts.months.6"),
      //             // i18n("home.charts.months.7")
      //             'Jan',
      //             'Feb',
      //             'Mar',
      //             'Apr',
      //             'May',
      //             'Jun',
      //             'Jul',
      //             'Aug',
      //             'Sep',
      //             'Oct',
      //             'Nov',
      //             'Dec'
      //           ],
      //           // datasets: [
      //           //   {
      //           //     label: i18n("home.charts.green"),
      //           //     fill: false,
      //           //     lineTension: 0.1,
      //           //     backgroundColor: "rgba(75,192,192,0.4)",
      //           //     borderColor: "rgba(75,192,192,1)",
      //           //     borderCapStyle: "butt",
      //           //     borderDash: [],
      //           //     borderDashOffset: 0.0,
      //           //     borderJoinStyle: "miter",
      //           //     pointBorderColor: "rgba(75,192,192,1)",
      //           //     pointBackgroundColor: "#fff",
      //           //     pointBorderWidth: 1,
      //           //     pointHoverRadius: 5,
      //           //     pointHoverBackgroundColor: "rgba(75,192,192,1)",
      //           //     pointHoverBorderColor: "rgba(220,220,220,1)",
      //           //     pointHoverBorderWidth: 2,
      //           //     pointRadius: 1,
      //           //     pointHitRadius: 10,
      //           //     data: [65, 59, 80, 81, 56, 55, 40]
      //           //   }
      //           // ],
      //           datasets: [
      //             {
      //               label: '# of Votes',
      //               data: [0, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200],
      //               borderWidth: 2,
      //               borderColor: '#808080',
      //               backgroundColor: 'transparent',
      //               pointBorderColor: 'transparent',
      //               pointBorderWidth: 1,
      //               pointHoverRadius: 5,
      //             },
      //           ]
      //         }
      // },
      // clients: {
      //         type: "line",
      //         options: {
      //           legend: {
      //             display: false
      //           },
      //           scales: {
      //             xAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ],
      //             yAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ]
      //           },
      //         },
      //         data: {
      //           labels: [
      //             // i18n("home.charts.months.1"),
      //             // i18n("home.charts.months.2"),
      //             // i18n("home.charts.months.3"),
      //             // i18n("home.charts.months.4"),
      //             // i18n("home.charts.months.5"),
      //             // i18n("home.charts.months.6"),
      //             // i18n("home.charts.months.7")
      //             'Jan',
      //             'Feb',
      //             'Mar',
      //             'Apr',
      //             'May',
      //             'Jun',
      //             'Jul',
      //             'Aug',
      //             'Sep',
      //             'Oct',
      //             'Nov',
      //             'Dec'
      //           ],
      //           datasets: [
      //             {
      //               label: '# of Votes',
      //               data: [0, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200],
      //               borderWidth: 2,
      //               borderColor: '#000000',
      //               backgroundColor: 'transparent',
      //               pointBorderColor: 'transparent',
      //               pointBorderWidth: 1,
      //               pointHoverRadius: 5,
      //             },
      //           ]
      //         }
      // },
      // tourguides: {
      //         type: "line",
      //         options: {
      //           legend: {
      //             display: false
      //           },
      //           scales: {
      //             xAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ],
      //             yAxes: [
      //               {
      //                 ticks: {
      //                   display: false
      //                 },
      //                 gridLines: {
      //                   display: false
      //                 }
      //               }
      //             ]
      //           },
      //         },
      //         data: {
      //           labels: [
      //             // i18n("home.charts.months.1"),
      //             // i18n("home.charts.months.2"),
      //             // i18n("home.charts.months.3"),
      //             // i18n("home.charts.months.4"),
      //             // i18n("home.charts.months.5"),
      //             // i18n("home.charts.months.6"),
      //             // i18n("home.charts.months.7")
      //             'Jan',
      //             'Feb',
      //             'Mar',
      //             'Apr',
      //             'May',
      //             'Jun',
      //             'Jul',
      //             'Aug',
      //             'Sep',
      //             'Oct',
      //             'Nov',
      //             'Dec'
      //           ],
      //           // datasets: [
      //           //   {
      //           datasets: [
      //             {
      //               label: '# of Votes',
      //               data: [0, 200, 250, 200, 500, 450, 850, 1050, 950, 1100, 900, 1200],
      //               borderWidth: 2,
      //               borderColor: '#808080',
      //               backgroundColor: 'transparent',
      //               pointBorderColor: 'transparent',
      //               pointBorderWidth: 1,
      //               pointHoverRadius: 5,
      //             },
      //           ]
      //         }
      // },
    };
  },

  computed: {
    completedTripsConfig() {
      if (this.completedTrips != null) {
        return {
          type: 'doughnut',
          options: {
            legend: {
              display: false,
            },
            cutoutPercentage: 80,
          },
          data: {
            labels: [
              'Uncompleted Trips',
              'Completed Trips',
            ],
            datasets: [
              {
                data: [
                  this.completedTrips.numberOfTrips -
                    this.completedTrips
                      .numberOfCompletedTrips,
                  this.completedTrips
                    .numberOfCompletedTrips,
                ],
                backgroundColor: ['transparent', '#f4b805'],
                borderWidth: 0,
                borderColor: '#fff',
              },
            ],
          },
        };
      }
      return null;
    },
    bar() {
      if (!this.statistics) {
        return;
      }
      return {
        type: 'bar',
        data: {
          labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
          datasets: [
            {
              label: 'Clients',
              data: [
                this.statistics.clients.week1,
                this.statistics.clients.week2,
                this.statistics.clients.week3,
                this.statistics.clients.week4,
              ],
              borderWidth: 2,
              borderColor: '#49c045',
              backgroundColor: '#49c045',
              pointBorderColor: '#49c045',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
            },
            {
              label: 'Tour Guides',
              data: [
                this.statistics.tourguides.week1,
                this.statistics.tourguides.week2,
                this.statistics.tourguides.week3,
                this.statistics.tourguides.week4,
              ],
              borderWidth: 2,
              borderDash: [2, 2],
              borderColor: '#fcf2d4',
              backgroundColor: '#fcf2d4',
              pointBorderColor: '#fcf2d4',
            },
          ],
        },
        options: {
          xAxes: [
                {
                  ticks: {
                    fontSize: '12',
                    fontColor: '#777777',
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    fontSize: '12',
                    fontColor: '#777777',
                    callback: function(value) {
                      return value + ' SAR';
                    },
                  },
                  gridLines: {
                    color: '#D8D8D8',
                    zeroLineColor: '#D8D8D8',
                    borderDash: [2, 2],
                    zeroLineBorderDash: [2, 2],
                    drawBorder: false,
                  },
                },
              ],
        },
      };
    },
    companyLineConfig() {
      if (this.newAdmins != null) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
            ],
            datasets: [
              {
                label: '# of new companies',
                data: [
                  this.newAdmins.week1,
                  this.newAdmins.week2,
                  this.newAdmins.week3,
                  this.newAdmins.week4,
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    clientLineConfig() {
      if (this.newClients != null) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
            ],
            datasets: [
              {
                label: '# of new clients',
                data: [
                  this.newClients.week1,
                  this.newClients.week2,
                  this.newClients.week3,
                  this.newClients.week4,
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    guideLineConfig() {
      if (this.newTourguides != null) {
        return {
          type: 'line',
          options: {
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: false,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
          data: {
            labels: [
              'Week 1',
              'Week 2',
              'Week 3',
              'Week 4',
            ],
            datasets: [
              {
                label: '# of new guides',
                data: [
                  this.newTourguides.week1,
                  this.newTourguides.week2,
                  this.newTourguides.week3,
                  this.newTourguides.week4,
                ],
                borderWidth: 2,
                borderColor: '#36B1BF',
                backgroundColor: 'transparent',
                pointBorderColor: 'transparent',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
              },
            ],
          },
        };
      }
      return null;
    },
    totalNewAdmins() {
      if (this.newAdmins != null) {
        var sum = 0;
        Object.keys(this.newAdmins).forEach((key) => {
          sum += this.newAdmins[key];
        });
        return sum;
      }
      return null;
    },
    totalNewClients() {
      if (this.newClients != null) {
        var sum = 0;
        Object.keys(this.newClients).forEach((key) => {
          sum += this.newClients[key];
        });
        return sum;
      }
      return null;
    },
    totalNewTourguides() {
      if (this.newTourguides != null) {
        var sum = 0;
        Object.keys(this.newTourguides).forEach((key) => {
          sum += this.newTourguides[key];
        });
        return sum;
      }
      return null;
    },
    ...mapGetters({
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
      completedTrips: 'home/completedTrips',
      profit: 'home/profit',
      offers: 'home/offers',
      newAdmins: 'home/newAdmins',
      newClients: 'home/newClients',
      newTourguides: 'home/newTourguides',
      statistics: 'home/statistics',
      loading: 'home/loading',
    }),
    barHeight() {
      console.log(this.is_screen_ipad_pro);
      // debugger
      if (this.is_screen_ipad_pro) {
        // debugger
        return 570;
      } else if (this.is_screen_xs || this.is_screen_sm) {
        return 400;
      } else if (this.is_screen_md) {
        return 270;
      } else {
        return 160;
      }
    },
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      doFetchDashboard: 'home/doFetchDashboard',
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
  },
};
