//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { PlanModel } from '@/modules/plan/plan-model';
import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

const { fields } = PlanModel;

export default {
  name: 'app-active-tour-guides-list-table',

  // mounted() {
  //   this.doMountTable(this.$refs.table);
  // },

  data() {
    return {
      language: getLanguageCode(),
      selected_ID: '',
      Pagination: {
        rowsPerPage: 10
      },
      columns: [
        {
          name: 'avatar',
          field: 'avatar',
          label: i18n('home.fields.avatar'),
          format: val => `${val}`,
          align: 'left',
        },
        {
          name: 'fullName',
          field: 'fullName',
          label: i18n('home.fields.fullName'),
          format: val => `${val}`,
          align: 'left',
        },
        // {
        //   name: 'branch',
        //   field: 'branch',
        //   label: i18n('home.fields.branch'),
        //   format: val => `${val}`,
        //   align: 'left',
        // },
        {
          name: 'rate',
          field: 'rate',
          label: i18n('home.fields.rate'),
          format: val => `${val}`,
          align: 'right',
        },
      ],
      rows: [
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 4,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 2,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3.5,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 5,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 2.5,
        },
        {
          avatar: 'https://cdn.quasar.dev/img/avatar.png',
          fullName: {
            en: 'Majed Abdullah',
            ar: 'ماجد عبدالله'
          },
          branch: {
            name: {
              en: 'Taif Branch',
              ar: 'فرع الطائف',
            },
          },
          rate: 3,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      // rows: 'plan/list/rows',   
      loading: 'plan/list/loading',
      pagination: 'plan/list/pagination',
      currentUser: 'auth/currentUser',
      destroyLoading: 'plan/destroy/loading',
      
      currentLanguageCode: 'layout/currentLanguageCode',
      menuCollapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop',
      is_screen_ipad_pro: 'layout/is_screen_ipad_pro',
    }),
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    fields() {
      return fields;
    },
  },

  methods: {
    ...mapActions({
      doMountTable: 'plan/list/doMountTable',
      doDestroy: 'plan/destroy/doDestroy',
    }),
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return PlanModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val[this.language]
    },
    presenterDate(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("D MMM, YYYY"); 
    },
    presenterDay(row, fieldName) {
      return moment(PlanModel.presenter(row, fieldName)).locale(this.language).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = PlanModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.language).format("hh:mm a"); 
    },
  },
};
